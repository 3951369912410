import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltroPipe } from './filtro.pipe';
import { DomSanitizerPipe } from './dom-sanitizer.pipe';
import { SafePipe } from './safe.pipe';
import { ImageSanitizerPipe } from '../image-sanitizer.pipe';
import { LimpiaExtensionPipe } from './limpia-extension.pipe';
import { FiltroReceptoresPipe } from './filtro-receptores.pipe';
import { FiltradorPipe } from './filtrador.pipe';
import { ReversePipe } from './reverse.pipe';



@NgModule({
  declarations: [FiltroPipe, DomSanitizerPipe, SafePipe, ImageSanitizerPipe, LimpiaExtensionPipe, FiltroReceptoresPipe, FiltradorPipe,ReversePipe],
  imports: [
    CommonModule
  ],
  exports: [FiltroPipe, DomSanitizerPipe, SafePipe, ImageSanitizerPipe, LimpiaExtensionPipe, FiltroReceptoresPipe, FiltradorPipe,ReversePipe]
})
export class PipesModule { }
