import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ComponentesModule } from './componentes/componentes.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IonicStorageModule } from '@ionic/storage';
import { Device } from '@ionic-native/device/ngx';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { DragDropDirective } from './componentes/drag-drop.directive';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IntlInputPhoneModule } from 'intl-input-phone';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Market } from '@ionic-native/market/ngx';
import { SignaturePadModule } from 'angular2-signaturepad';
import { PipesModule } from './pipes/pipes/pipes.module';
import { FiltradorPipe } from './pipes/pipes/filtrador.pipe';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';


@NgModule({
  declarations: [AppComponent, DragDropDirective],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    ComponentesModule,
    PipesModule,
    NgbModule,
    HttpClientModule,
    PdfJsViewerModule,
    DeviceDetectorModule.forRoot(),
    IonicStorageModule.forRoot(),
    IntlInputPhoneModule,
    SignaturePadModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    StatusBar,
    Device,
    InAppBrowser,
    Geolocation,
    File,
    FileOpener,
    Diagnostic,
    FileTransfer,
    Market,
    HttpClient,
    SplashScreen,
    FiltradorPipe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
