import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './servicios/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  /*{
    path: 'directorio',
    loadChildren: () => import('./pages/directorio/directorio.module').then( m => m.DirectorioPageModule),
    canActivate: [AuthGuardService]
  },*/
  {
    path: 'directorio',
    loadChildren: () => import('./pages/directorios/directorios.module').then(m => m.DirectoriosPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'visor-pdf/:id/:force',
    loadChildren: () => import('./pages/visor-pdf/visor-pdf.module').then(m => m.VisorPdfPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'visor-pdf/:id',
    loadChildren: () => import('./pages/visor-pdf/visor-pdf.module').then(m => m.VisorPdfPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'visor-plantillas/:id',
    loadChildren: () => import('./pages/visor-plantillas/visor-plantillas.module').then(m => m.VisorPlantillasPageModule),
    canActivate: [AuthGuardService]
  },

  {
    path: 'verificador/:id',
    loadChildren: () => import('./pages/verificador/verificador.module').then( m => m.VerificadorPageModule)
  },
  {
    path: 'formulario/:id',
    loadChildren: () => import('./pages/formulario/formulario.module').then( m => m.FormularioPageModule)
  },
  {
    path: 'somos',
    loadChildren: () => import('./pages/quienes-somos/quienes-somos.module').then( m => m.QuienesSomosPageModule)
  },
  {
    path: 'tarifas',
    loadChildren: () => import('./pages/tarifas/tarifas.module').then( m => m.TarifasPageModule)
  },
  {
    path: 'contacto',
    loadChildren: () => import('./pages/contacto/contacto.module').then( m => m.ContactoPageModule)
  },
  {
    path: 'proteccion-datos',
    loadChildren: () => import('./pages/proteccion-datos/proteccion-datos.module').then( m => m.ProteccionDatosPageModule)
  },
  {
    path: 'legal',
    loadChildren: () => import('./pages/aviso-legal/aviso-legal.module').then( m => m.AvisoLegalPageModule)
  },
  {
    path: 'cookies',
    loadChildren: () => import('./pages/cookies/cookies.module').then( m => m.CookiesPageModule)
  },
  {
    path: 'csv',
    loadChildren: () => import('./pages/csv/csv.module').then( m => m.CsvPageModule)
  },
  {
    path: 'contratar/:pack/:rellenable/:plataforma',
    loadChildren: () => import('./pages/contratar/contratar.module').then( m => m.ContratarPageModule)
  },
  {
    path: 'contratar',
    loadChildren: () => import('./pages/contratar/contratar.module').then(m => m.ContratarPageModule)
  },
  {
    path: 'empresa/:empresa',
    loadChildren: () => import('./pages/acceso-empresas/acceso-empresas.module').then( m => m.AccesoEmpresasPageModule)
  },
  {
    path: 'directorio-receptores',
    loadChildren: () => import('./pages/directorio-receptores/directorio-receptores.module').then(m => m.DirectorioReceptoresPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'avisos/:id',
    loadChildren: () => import('./pages/avisos/avisos.module').then( m => m.AvisosPageModule)
  },
  {
    path: 'directorios',
    loadChildren: () => import('./pages/directorios/directorios.module').then(m => m.DirectoriosPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'firmas',
    loadChildren: () => import('./pages/firmas/firmas.module').then( m => m.FirmasPageModule)
  },
  {
    path: 'directorio-agentes',
    loadChildren: () => import('./pages/directorio-agentes/directorio-agentes.module').then(m => m.DirectorioAgentesPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'descargar-pdf/:id_generado/:rol/:id_usuario',
    loadChildren: () => import('./pages/descargar-pdf/descargar-pdf.module').then( m => m.DescargarPdfPageModule)
  },
  {
    path: 'copiar-ficheros',
    loadChildren: () => import('./pages/copiar-ficheros/copiar-ficheros.module').then( m => m.CopiarFicherosPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'control-biometrico/:firmante/:rol/:id_generado/:forzado/:volver',
    loadChildren: () => import('./pages/control-biometrico/control-biometrico.module').then( m => m.ControlBiometricoPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'compra',
    loadChildren: () => import('./pages/compra/compra.module').then( m => m.CompraPageModule)
  },
  {
    path: 'visor-csv',
    loadChildren: () => import('./pages/visor-csv/visor-csv.module').then( m => m.VisorCsvPageModule)
  },
  /*
  {
    path: 'carga-doc',
    loadChildren: () => import('./pages/carga-doc/carga-doc.module').then( m => m.CargaDocPageModule)
  },
  */
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
      // enableTracing: true // <-- Sólo para debug
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
