import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { Firmantes } from 'src/app/interfaces/interfaces';
import { ConfigurationOptions, IntlInputPhoneComponent } from 'intl-input-phone';
import { PrincipalService } from 'src/app/servicios/principal.service';
import * as $ from 'jquery';
export class PopoverComponent {
    constructor(modal, serv, alertController) {
        this.modal = modal;
        this.serv = serv;
        this.alertController = alertController;
        this.titulo = '';
        this.firmante = {
            id: 0,
            nombre: '',
            email: '',
            numero_telefono: '',
            fecha_creacion: '',
            rol: 3,
            en_proceso: 0,
            prefijo: '',
            tipo_documento: 'DNI/NIE',
            dni: '',
            alias: ''
        };
        this.prefijo = '34';
        this.SelectedCountryISOCode = 'ES';
        this.planes = null;
        this.configOption1 = new ConfigurationOptions();
        this.configOption1.SelectorClass = 'InputValidation3';
    }
    ngOnInit() {
    }
    ionViewWillEnter() {
        if (this.tipo == 'editaFirmante') {
            this.firmante = this.editaFirm;
        }
        if (this.tipo == 'info_tarifa') {
            this.serv.getPlanes().subscribe((c) => {
                if (c.ok) {
                    this.planes = c.planes;
                    this.plan_info = this.planes.find((p) => p.id == this.mensaje);
                }
                else {
                    console.error(c.error);
                }
            });
        }
    }
    cerrarModal() {
        this.modal.dismiss({
            firmante: this.firmante
        });
    }
    confirmar() {
        this.firmante.fecha_creacion = this.serv.getFechaActual();
        this.mensaje = '';
        const prefijo = $('.CountryCode')[0];
        this.firmante.prefijo = $(prefijo).text();
        this.firmante.prefijo = this.firmante.prefijo.replace('+', '');
        if (this.firmante.nombre.length < 3) {
            this.mensaje = 'Debe introducir un nombre válido para el receptor';
            setTimeout(() => {
                this.mensaje = '';
            }, 3000);
            return;
        }
        if (!this.firmante.email.includes('@') || !this.firmante.email.includes('.')) {
            this.mensaje = 'Debe introducir un email valido para el receptor';
            setTimeout(() => {
                this.mensaje = '';
            }, 3000);
            return;
        }
        if (((this.firmante.tipo_documento == 'DNI/NIE') && !this.validaDni(this.firmante.dni)) || ((this.firmante.dni || '').length < 8)) {
            this.mensaje = 'Debe introducir un DNI válido para el receptor';
            setTimeout(() => {
                this.mensaje = '';
            }, 3000);
            return;
        }
        this.cerrarModal();
    }
    EditaFirmante() {
        this.serv.setActualizaFirmante(this.firmante).subscribe((c) => {
            if (c.ok) {
                this.cerrarModalEdit();
            }
            else {
                this.presentAlert('Error al modificar el firmante, intentelo de nuevo mas tarde');
            }
        });
    }
    presentAlert(message) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                header: 'Atención',
                message,
                buttons: ['Aceptar']
            });
            yield alert.present();
        });
    }
    cerrarModalEdit() {
        this.modal.dismiss({
            editado: true
        });
    }
    validaDni(dni) {
        if ((dni || '').length !== 9) {
            return false;
        }
        let numero, letr, letra;
        const expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;
        dni = dni.toUpperCase();
        if (expresion_regular_dni.test(dni) == true) {
            numero = dni.substr(0, dni.length - 1);
            numero = numero.replace('X', '0');
            numero = numero.replace('Y', '1');
            numero = numero.replace('Z', '2');
            letr = dni.substr(dni.length - 1, 1);
            numero = (parseInt(numero) % 23).toString();
            letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
            letra = letra.substring(parseInt(numero), ((parseInt(numero)) + 1));
            return letra == letr;
        }
        return false;
    }
}
