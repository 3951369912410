import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: any;
  rutaWS = `${environment.rutaWS}${environment.puerto}/`;
  constructor(private router: Router,
              private storage: Storage,
              private http: HttpClient) { }


  public userProfile: any;

  // auth0 = new auth0.WebAuth({
  //   clientID: '4H5gt8Zn6p1PLwxMcIaRa9YayKQzWVFk',
  //  /* domain: 'strider.auth0.com',*/
  //   responseType: 'token id_token',
  //  /* audience: 'https://strider.auth0.com/userinfo',*/
  //   redirectUri: 'http://localhost:8100/callback',
  //   scope: 'openid profile'
  // });


  async grabaSession(token: any) {

    this.setSession(token.token);

    await this.storage.remove('usuario');
    await this.storage.set('usuario', token.usuario);
    if (token.usuario.cambiaRoles == 1) {
      await this.storage.remove('superUser');
      await this.storage.set('superUser', {
        super: true,
        id: token.usuario.id
      });
    } else {
      await this.storage.remove('superUser');
      await this.storage.set('superUser', {
        super: false
      });
    }
    if (token.usuario.rol  == 4) {
      this.router.navigate(['/directorio-agentes']);
    } else {
      this.router.navigate(['/directorio']);
    }

  }
  grabaSessionDescarga(token) {
    this.setSession(token);
  }
  async grabaSessionInvitado(resp) {
    this.setSession(resp.resultado.token);

    await this.storage.remove('receptor');
    return await this.storage.set('receptor', resp.receptor);

  }
  async grabaSessionReceptor(resp) {
    this.setSession(resp.respuesta);

    await this.storage.remove('receptor');
    return await this.storage.set('receptor', resp.receptor);

  }
    private setSession(token): void {
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify((1000 * 60 * 60) + new Date().getTime());
    localStorage.setItem('access_token', token);
    localStorage.setItem('expires_at', expiresAt);
  }
  removeSession() {

    this.storage.remove('usuario');
    this.storage.remove('receptor');
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('expires_at');
  }
  public logout(): void {
    this.storage.remove('usuario');
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('expires_at');
    // Go back to the home route
    this.router.navigate(['/login']);
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  public getProfile(cb): void {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      throw new Error('Access token must exist to fetch profile');
    }
  }
  async sigueEnSesion() {
    /* Vamos a ver si seguimos en session tanto abajo como arriba */
    if (this.isAuthenticated()) {
      const headers = new HttpHeaders({
        'x-token': localStorage.getItem('access_token')
      });


      return this.http.get(`${this.rutaWS}validado`, { headers }).subscribe((resp: any) => {

        if (resp.respuesta) {
          localStorage.removeItem('expires_at');
          const expiresAt = JSON.stringify((1000 * 60 * 60) + new Date().getTime());
          localStorage.setItem('expires_at', expiresAt);
          return true;
        } else {
          this.router.navigate(['/login']);
        }
      });
    } else {
      this.router.navigate(['/login']);
      return false;
    }

  }

}
