import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/servicios/auth.service';

@Component({
  selector: 'app-encabezado',
  templateUrl: './encabezado.component.html',
  styleUrls: ['./encabezado.component.scss'],
})
export class EncabezadoComponent implements OnInit {
  
  constructor(private auth: AuthService) { }

  ngOnInit() {
  
  }
  logOut(){
    this.auth.logout();
  }
}
