import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { Firmantes, Receptor } from 'src/app/interfaces/interfaces';
import { ConfigurationOptions, IntlInputPhoneComponent } from 'intl-input-phone';
import { PrincipalService } from 'src/app/servicios/principal.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {
  @Input() tipo: string;
  @Input() mensaje: string;
  @Input() titulo = '';
  @Input() editaFirm: Firmantes;
  @ViewChild('pref', null) pref: IntlInputPhoneComponent;
  firmante: Firmantes = {
    id: 0,
    nombre: '',
    email: '',
    numero_telefono: '',
    fecha_creacion: '',
    rol: 3,
    en_proceso: 0,
    prefijo: '',
    tipo_documento: 'DNI/NIE',
    dni: '',
    alias: ''
  };
  configOption1: ConfigurationOptions;
  prefijo = '34';
  SelectedCountryISOCode = 'ES';
  planes: any = null;
  plan_info: any;
  constructor(private modal: ModalController,
              private serv: PrincipalService,
              private alertController: AlertController) {
    this.configOption1 = new ConfigurationOptions();
    this.configOption1.SelectorClass = 'InputValidation3';
   }

  ngOnInit() {
  }
  ionViewWillEnter() {
    if (this.tipo == 'editaFirmante') {
      this.firmante = this.editaFirm;
    }
    if (this.tipo == 'info_tarifa') {
      this.serv.getPlanes().subscribe((c: any) => {
        if (c.ok) {
          this.planes = c.planes;
          this.plan_info = this.planes.find((p: any) => p.id == this.mensaje);
        } else {
          console.error(c.error);
        }
      });
    }
  }
  cerrarModal() {
    this.modal.dismiss({
      firmante: this.firmante
    });
  }
  confirmar() {
    this.firmante.fecha_creacion = this.serv.getFechaActual();
    this.mensaje = '';
    const prefijo: any = $('.CountryCode')[0];
    this.firmante.prefijo = $(prefijo).text();
    this.firmante.prefijo = this.firmante.prefijo.replace('+', '');
    if (this.firmante.nombre.length < 3) {
      this.mensaje = 'Debe introducir un nombre válido para el receptor';
      setTimeout(() => {
        this.mensaje = '';
      }, 3000);
      return;
    }
    if (!this.firmante.email.includes('@') || !this.firmante.email.includes('.')) {
      this.mensaje = 'Debe introducir un email valido para el receptor';
      setTimeout(() => {
        this.mensaje = '';
      }, 3000);
      return;
    }
    if (((this.firmante.tipo_documento == 'DNI/NIE') && !this.validaDni(this.firmante.dni)) || ((this.firmante.dni || '').length < 8)) {
      this.mensaje = 'Debe introducir un DNI válido para el receptor';
      setTimeout(() => {
        this.mensaje = '';
      }, 3000);
      return;
    }

    this.cerrarModal();
  }
  EditaFirmante() {
    this.serv.setActualizaFirmante(this.firmante).subscribe((c: any) => {
      if (c.ok) {
        this.cerrarModalEdit();
      } else {
        this.presentAlert('Error al modificar el firmante, intentelo de nuevo mas tarde');
      }
    });
  }
  async presentAlert(message: string) {
    const alert = await this.alertController.create({
      header: 'Atención',
      message,
      buttons: ['Aceptar']
    });

    await alert.present();
  }
  cerrarModalEdit() {
    this.modal.dismiss({
      editado: true
    });
  }

  private validaDni(dni: string) {
    if ((dni || '').length !== 9) {
      return false;
    }

    let numero: string, letr: string, letra: string;
    const expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

    dni = dni.toUpperCase();

    if (expresion_regular_dni.test(dni) == true) {
      numero = dni.substr(0, dni.length - 1);
      numero = numero.replace('X', '0');
      numero = numero.replace('Y', '1');
      numero = numero.replace('Z', '2');
      letr = dni.substr(dni.length - 1, 1);
      numero = (parseInt(numero) % 23).toString();
      letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
      letra = letra.substring(parseInt(numero), ((parseInt(numero)) + 1));
      return letra == letr;
    }
    return false;
  }
}
