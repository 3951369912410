import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {
    this.initializeApp();
  }

  private initializeApp() {
    this.platform.ready().then(async () => {
      if ((!this.platform.is('ios') && !this.platform.is('android'))) {
        sessionStorage.setItem('plataforma', 'WEB');
      } else {
        if (this.platform.is('ios')) {
          sessionStorage.setItem('plataforma', 'IOS');
        } else {
          if (this.platform.is('android')) {
            sessionStorage.setItem('plataforma', 'ANDROID');
          }
        }
      }
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
