<ion-content *ngIf="tipo=='info'">
    <ion-list>
        <ion-list-header *ngIf="titulo > ''">
            <ion-label>{{titulo}}</ion-label>
        </ion-list-header>
        <ion-item lines="none">
            <ion-icon name="information-circle-outline" slot="start"></ion-icon>
            <ion-label>{{mensaje}}</ion-label>
        </ion-item>
    </ion-list>
</ion-content>
<ion-content *ngIf="tipo == 'nuevoFirmante'">
    <app-encabezado></app-encabezado>
    <ion-grid fixed id="nuevo_firmante">
        <ion-row>
            <ion-col size-md="8" offset-md="2" size-xs="12" offset-xs="0">
                <ion-item class="usuario" lines="none">
                    <ion-title>Nuevo Firmante</ion-title>
                </ion-item>
                <ion-item class="hijos">
                    <ion-label>Nombre
                        <ion-text color="danger">*</ion-text>
                    </ion-label>
                    <ion-input [(ngModel)]="firmante.nombre" required type="text" placeholder="(obligatorio)"></ion-input>
                </ion-item>
                <ion-item class="hijos">
                    <ion-label>Alias</ion-label>
                    <ion-input [(ngModel)]="firmante.alias" type="text" placeholder="(opcional)"></ion-input>
                </ion-item>
                <ion-item class="hijos">
                    <ion-label>Email
                        <ion-text color="danger">*</ion-text>
                    </ion-label>
                    <ion-input [(ngModel)]="firmante.email" required type="email" placeholder="ejemplo@email.com"></ion-input>
                </ion-item>
                <ion-item class="hijos">
                    <ion-label>Tipo de Documento:</ion-label>
                    <ion-select value="{{firmante.tipo_documento}}" [(ngModel)]="firmante.tipo_documento">
                        <ion-select-option value="DNI/NIE">DNI/NIE</ion-select-option>
                        <ion-select-option value="Pasaporte">Pasaporte</ion-select-option>
                    </ion-select>
                </ion-item>
                <ion-item class="hijos">
                    <ion-label>Nº de Documento
                        <ion-text color="danger">*</ion-text>
                    </ion-label>
                    <ion-input [(ngModel)]="firmante.dni" required type="text" placeholder="Introducir número sin guiones ni espacios"></ion-input>
                </ion-item>
                <ion-item class="hijos" id="ultimo_hijo">
                    <ion-label position="stacked">Teléfono</ion-label>
                    <ion-row>
                        <ion-col size="4" class="ion-margin-top">
                            <intl-input-phone [ConfigurationOption]="configOption1" [SelectedCountryISOCode]="SelectedCountryISOCode" [NumberTextValue]="prefijo" #pref></intl-input-phone>
                        </ion-col>
                        <ion-col size="8">
                            <ion-input style="margin-top: 10px;" [(ngModel)]="firmante.numero_telefono" placeholder="(opcional)" type="tel">
                            </ion-input>
                        </ion-col>
                    </ion-row>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size-md="3" offset-md="3" size-xs="6" offset-xs="0">
                <ion-button (click)="cerrarModal()" expand="block" shape="round" color="granate">
                    Cancelar
                </ion-button>
            </ion-col>
            <ion-col size-md="3" size-xs="6">
                <ion-button (click)="confirmar()" expand="block" shape="round" color="granate">
                    Confirmar
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="12">
                <ion-text color="danger" *ngIf="mensaje >''">
                    {{mensaje}}
                </ion-text>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
<ion-content *ngIf="tipo == 'editaFirmante'">
    <ion-toolbar color="granate">
        <ion-buttons slot="end">
            <ion-button (click)="cerrarModal()" title="Desconectar">
                <ion-icon title="cerrarModal" slot="icon-only" name="close-circle-outline" color="light" class="cursor ion-margin-end" size="large"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
    <ion-grid fixed id="edita_firmante">
        <ion-row>
            <ion-col size-md="8" offset-md="2" size-xs="12" offset-xs="0">
                <ion-item class="usuario" lines="none">
                    <ion-title>Editar Firmante</ion-title>
                </ion-item>
                <ion-item class="hijos">
                    <ion-label>Nombre
                        <ion-text color="danger">*</ion-text>
                    </ion-label>
                    <ion-input [(ngModel)]="firmante.nombre" required type="text" placeholder="(obligatorio)">
                    </ion-input>
                </ion-item>
                <ion-item class="hijos">
                    <ion-label>Alias
                        <ion-text color="danger">*</ion-text>
                    </ion-label>
                    <ion-input [(ngModel)]="firmante.alias" type="text" placeholder="(opcional)"></ion-input>
                </ion-item>
                <ion-item class="hijos">
                    <ion-label>Email
                        <ion-text color="danger">*</ion-text>
                    </ion-label>
                    <ion-input [(ngModel)]="firmante.email" required type="email" placeholder="(obligatorio)">
                    </ion-input>
                </ion-item>
                <ion-item class="hijos">
                    <ion-label>Tipo de Documento:</ion-label>
                    <ion-select value="{{firmante.tipo_documento}}" [(ngModel)]="firmante.tipo_documento">
                        <ion-select-option value="DNI/NIE">DNI/NIE</ion-select-option>
                        <ion-select-option value="Pasaporte">Pasaporte</ion-select-option>
                    </ion-select>
                </ion-item>
                <ion-item class="hijos">
                    <ion-label>Nº de Documento</ion-label>
                    <ion-input type="text" placeholder="(obligatorio)" required [(ngModel)]="firmante.dni">
                    </ion-input>
                </ion-item>
                <ion-item class="hijos" id="ultimo_hijo">
                    <ion-label position="stacked">Teléfono</ion-label>
                    <ion-row>
                        <ion-col size="4" class="ion-margin-top">
                            <intl-input-phone [ConfigurationOption]="configOption1" [SelectedCountryISOCode]="SelectedCountryISOCode" [NumberTextValue]="prefijo" #pref>
                            </intl-input-phone>
                        </ion-col>
                        <ion-col size="8">
                            <ion-input style="margin-top: 10px;" [(ngModel)]="firmante.numero_telefono" placeholder="(opcional)" type="tel">
                            </ion-input>
                        </ion-col>
                    </ion-row>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size-md="3" offset-md="3" size-xs="6" offset-xs="0">
                <ion-button (click)="cerrarModal()" expand="block" shape="round" color="granate">
                    Cancelar
                </ion-button>
            </ion-col>
            <ion-col size-md="3" size-xs="6">
                <ion-button (click)="EditaFirmante()" expand="block" shape="round" color="granate">
                    Confirmar
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="12">
                <ion-text color="danger" *ngIf="mensaje >''">
                    {{mensaje}}
                </ion-text>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
<ion-content *ngIf="tipo == 'info_tarifa'">
    <ion-card *ngIf="plan_info != null">
        <ion-card-header style="background-color: #4a0000;">
            <ion-card-title class="ion-text-center" color="light">{{plan_info.nombre}} : {{plan_info.precio_original}}/mes + IVA<br> <small>(pago anual)</small></ion-card-title>
        </ion-card-header>

        <ion-card-content class="ion-margin-top">
            <ul>
                <li>{{plan_info.mb_almacenamiento}} MB de almacenamiento</li>
                <li>1 año de vigencia</li>
                <li *ngIf="plan_info.numero_usuarios > 0">{{plan_info.numero_usuarios}} usuarios</li>
                <li *ngIf="plan_info.numero_usuarios == 0">Usuarios ilimitados</li>
                <li>Atención cliente y SAT</li>
                <li *ngIf="plan_info.permite_rubrica == 1">Firma por rúbrica</li>
                <li *ngIf="plan_info.permite_certificado == 1">Firma por certificado</li>
                <li *ngIf="plan_info.eliminar_firmas == 1">Borrado de firma</li>
                <li *ngIf="plan_info.eliminar_firmas == 0">Sin Borrado de firma</li>
                <li *ngIf="plan_info.api_compatible == 0">Sin API compatible</li>
                <li *ngIf="plan_info.api_compatible == 1">API compatible</li>
                <li *ngIf="plan_info.sellado_tiempo == 1">Sellado de tiempo</li>
                <li *ngIf="plan_info.sellado_tiempo == 0">Sin Sellado de tiempo</li>
                <li *ngIf="plan_info.verificar_sellado == 1">Verificar sellado</li>
                <li *ngIf="plan_info.verificar_sellado == 0">Sin Verificación de sellado</li>
                <li *ngIf="plan_info.permite_editar == 1">Autorrelleno doc. (opcional)</li>
                <li *ngIf="plan_info.permite_editar == 0">Sin Autorrelleno</li>
                <li *ngIf="plan_info.id == 16 || plan_info.id == 17 || plan_info.id == 18">Control de acceso biometrico a documentos</li>
                <li *ngIf="plan_info.permite_usuarios_receptores == 1">Plataforma de clientes (opcional)</li>
                <li *ngIf="plan_info.permite_usuarios_receptores == 0">Sin Plataforma de clientes</li>
            </ul>
            <ion-item class="togles" *ngIf="plan_info.permite_editar == 1">
                <ion-label>Autorrelleno: +{{plan_info.precio_relleno}}€/Mes</ion-label>
            </ion-item>
            <ion-item *ngIf="plan_info.permite_usuarios_receptores == 1">
                <ion-label>Plataforma de clientes: +{{plan_info.precio_plataforma_clientes}}€/Mes</ion-label>
            </ion-item>
        </ion-card-content>
    </ion-card>
</ion-content>