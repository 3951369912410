import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Device } from '@ionic-native/device/ngx';
import { PDFDocument } from 'pdf-lib';
import * as uuid from 'uuid';
import { Platform, AlertController, ToastController } from '@ionic/angular';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import * as saveAs from 'file-saver';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common/http";
import * as i3 from "./auth.service";
import * as i4 from "@ionic-native/device/ngx/index";
import * as i5 from "@ionic/angular";
import * as i6 from "@ionic-native/file/ngx/index";
import * as i7 from "@ionic-native/file-transfer/ngx/index";
import * as i8 from "@ionic-native/diagnostic/ngx/index";
export class PrincipalService {
    constructor(router, http, auth, device, platform, alert, file, fileTransfer, diagnostic, toastController) {
        this.router = router;
        this.http = http;
        this.auth = auth;
        this.device = device;
        this.platform = platform;
        this.alert = alert;
        this.file = file;
        this.fileTransfer = fileTransfer;
        this.diagnostic = diagnostic;
        this.toastController = toastController;
        this.rutaWS = `${environment.rutaWS}${environment.puerto}/`;
    }
    login(usuario) {
        const parametros = {
            email: usuario.email,
            pass: usuario.password,
            dispositivo: uuid.v1()
        };
        if (this.platform.is('android') || this.platform.is('ios')) {
            parametros.dispositivo = this.device.uuid;
        }
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${this.rutaWS}login`, parametros, { headers });
    }
    loginReceptor(usuario) {
        const parametros = {
            email: usuario.email,
            pass: usuario.password,
            dispositivo: uuid.v1(),
            endpoint: usuario.endpoint
        };
        if (this.platform.is('android') || this.platform.is('ios')) {
            parametros.dispositivo = this.device.uuid;
        }
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${this.rutaWS}loginReceptor`, parametros, { headers });
    }
    getReceptores(padre) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}getReceptores/${padre}`, { headers });
    }
    getReceptoresAgente(agente, padre) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}getReceptoresAgente/${agente}/${padre}`, { headers });
    }
    setGrabaFirmas(datos) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token'),
            'Content-Type': 'application/json'
        });
        return this.http.post(`${this.rutaWS}setGrabaFirmas`, datos, { headers });
    }
    getFirmasDoc(doc) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}getFirmasDoc/${doc}`, { headers });
    }
    setValidaDescarga(id_generado, id_usuario, rol) {
        return this.http.get(`${this.rutaWS}setValidaDescarga/${id_generado}/${rol}/${id_usuario}`);
    }
    getDoc(doc) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}getDoc/${doc}`, { headers });
    }
    getPermisosVerDoc(id_generado, rol, id_usuario) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}getPermisosVerDoc/${id_generado}/${rol}/${id_usuario}`, { headers });
    }
    /**
     * Sube un documento a la plataforma. Permite asignarle una plantilla.
     *
     * Ruta del WS utilizada (sin plantilla): /uploadPdf
     *
     * Ruta del WS utilizada (con plantilla): /api/documentos
     *
     * @param formData Datos que se van a subir.
     */
    subirImagenWeb(formData) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        let ruta = 'uploadPdf';
        const data = JSON.parse(formData.get('data').toString());
        // Si tiene plantilla, le cambiamos el formato para que sea el de la API.
        if ((data || {}).id_plantilla) {
            ruta = 'api/documentos';
            const receptor = {};
            receptor.nombre = formData.get('nombreReceptor');
            receptor.alias = formData.get('nuevoReceptorAlias');
            receptor.email = formData.get('emailReceptor');
            receptor.telefono = formData.get('telefonoReceptor');
            receptor.tipo_documento = formData.get('tipo_documentoReceptor');
            receptor.dni = formData.get('dniReceptor');
            receptor.prefijo = formData.get('prefijo');
            const firmantes = data.firmantes || [];
            formData.append('doc', JSON.stringify(data));
            formData.append('receptor', JSON.stringify(receptor));
            formData.append('firmantes', JSON.stringify(firmantes));
        }
        return this.http.post(`${this.rutaWS}${ruta}`, formData, { headers });
    }
    getDocPreparado(id_doc) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}getPreparaDocumento/${id_doc}`, { headers });
    }
    subirCert(formData) {
        return this.http.post(`${this.rutaWS}pruebasAlvaroCert`, formData);
    }
    actualizaDatosEditables(formData) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.post(`${this.rutaWS}actualizaDatosEditables`, formData, { headers });
    }
    subirPdfEditable(formData) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.post(`${this.rutaWS}uploadPdfEditable`, formData, { headers });
    }
    blobParaMoviles(fichero) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(fichero);
            reader.onloadend = (e) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const mediaType = 'application/pdf';
                const base64 = e.target.result;
                // tslint:disable-next-line:no-shadowed-variable
                PDFDocument.load(base64).then((e) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    const pdfDoc1 = e;
                    const pdfBytes = yield pdfDoc1.save();
                    const blob = new Blob([pdfBytes], { type: mediaType });
                    resolve(blob);
                })).catch(() => {
                    const aux = base64.split(',');
                    const byteCharacters = window.atob(aux[1]);
                    const byteArrays = [];
                    const sliceSize = 512;
                    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                        const slice = byteCharacters.slice(offset, offset + sliceSize);
                        const byteNumbers = new Array(slice.length);
                        for (let i = 0; i < slice.length; i++) {
                            byteNumbers[i] = slice.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        byteArrays.push(byteArray);
                    }
                    const blob = new Blob(byteArrays, { type: mediaType });
                    resolve(blob);
                });
            });
        });
    }
    blobParaMovilesImagenes(fichero) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(fichero);
            reader.onloadend = (e) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const base64 = e.target.result;
                const base_image = new Image();
                base_image.src = base64;
                base_image.onload = () => {
                    fetch(base_image.src)
                        .then((response) => {
                        resolve(response.blob());
                    });
                };
            });
        });
    }
    getVerificaRuta(id) {
        const parametros = {
            id
        };
        return this.http.get(`${this.rutaWS}verificaRuta/${id}`);
    }
    subirEnMovil(fichero, datos) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const options = {
                fileKey: 'pdf',
                headers: {
                    'x-token': localStorage.getItem('access_token')
                },
                chunkedMode: false,
                params: datos
            };
            const fileTransfer = this.fileTransfer.create();
            const data = yield fileTransfer.upload(URL.createObjectURL(fichero), `${this.rutaWS}uploadPdf`, options);
            let respServer = data.response;
            respServer = JSON.parse(respServer);
            return respServer;
            /*
                return new Promise(async(resolve, reject)=>{
                  try {
                    const options: FileUploadOptions = {
                      fileKey: 'pdf',
                      headers: {
                        'x-token': localStorage.getItem('access_token')
                      },
                      chunkedMode: false,
                      params:datos
                    };
                    let reader = new FileReader();
                    reader.onloadend = (e:any)=> {
            
                      let arrayBuffer = e.result,
                      array = new Uint8Array(arrayBuffer),
                      binaryString = String.fromCharCode.apply(null, array);
                      let fileReader = new FileReader();
            
                      let fileType = 'application/pdf';
                      let blob = blobUtil.arrayBufferToBlob(arrayBuffer, fileType);
                      fileReader.onloadend = async (e)=> {
            
                          const fileTransfer: FileTransferObject = this.fileTransfer.create();
            
                          let data = await fileTransfer.upload(URL.createObjectURL(fileReader), `${this.rutaWS}uploadPdf`, options);
            
                          let respServer: any = data.response;
                          respServer = JSON.parse(respServer);
                          resolve(respServer);
                        };
                        fileReader.readAsArrayBuffer(blob);
                    }
                    reader.readAsText(fichero);
            
            
                  } catch (e) {
                    reject(e);
                  }
                  });
               */
        });
    }
    getEnlace(receptor, id_doc) {
        const recept = {
            id: receptor.id,
            nombre: receptor.nombre,
            email: receptor.email,
            numero_telefono: receptor.numero_telefono,
            rol: receptor.rol,
            fecha_creacion: receptor.fecha_creacion
        };
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        const params = new FormData();
        params.append('receptor', JSON.stringify(recept));
        params.append('id_doc', '' + id_doc);
        return this.http.post(`${this.rutaWS}getEnlace`, params, { headers });
    }
    getDatosIp() {
        this.auth.sigueEnSesion();
        return this.http.get(`https://api.ipify.org/?format=json`, { responseType: 'text' }).toPromise();
    }
    setEliminaFirma(rol, id_doc, id_usuario) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}setEliminaFirma/${rol}/${id_doc}/${id_usuario}/false`, { headers });
    }
    setEliminaFirmaPosicionada(rol, id_doc, id_usuario) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}setEliminaFirma/${rol}/${id_doc}/${id_usuario}/true`, { headers });
    }
    descargarPdfFirmados(ruta) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token'),
            Accept: 'application/pdf'
        });
        return this.http.get(`${ruta}`, { headers, responseType: 'blob' });
    }
    presentAlert(message) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alert.create({
                header: 'Atención',
                message,
                buttons: ['Aceptar']
            });
            yield alert.present();
        });
    }
    getDisponibleParaDescargar(ruta, id_doc) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let aux = ruta.split('/');
                let id_generado = aux[aux.length - 1];
                aux = id_generado.split('.pdf');
                id_generado = aux[0];
                const doc = yield this.getDoc(id_generado).toPromise();
                let firmado;
                if (doc.respuesta == 'ok') {
                    firmado = yield this.getFirmadoPorTodos(doc.doc.id_doc, false).toPromise();
                }
                if (firmado.ok) {
                    if (firmado.firmadoPôrTodos) {
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    }
                }
                else {
                    resolve(false);
                }
            }));
        });
    }
    descargarPdfFirmadosBuffer(ruta) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${ruta}`, { headers, responseType: 'arraybuffer' });
    }
    getFechaActual() {
        return `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()} ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`;
    }
    getLimpizaFecha(fecha) {
        let fechaNueva;
        if ((fecha != null) && (fecha !== undefined)) {
            fechaNueva = fecha.replace('T', ' ');
            fechaNueva = fechaNueva.replace('Z', '');
        }
        else {
            fechaNueva = null;
        }
        return fechaNueva;
    }
    descargado(id_doc, usuario, rol) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}descargado/${id_doc}/${usuario}/${rol}`, { headers });
    }
    setEnviaCert(cert, id_generado) {
        const body = {
            cert,
            id_generado
        };
        return this.http.post(`${this.rutaWS}pruebaFirma`, body);
    }
    eliminaDoc(id_doc) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}setDocEliminado/${id_doc}`, { headers });
        // return this.http.get(`${this.rutaWS}setEliminaDocumento/${id_doc}`, {headers});
    }
    descargaDocRubrica(id_doc_generado, id_doc, usuario, rol) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.auth.sigueEnSesion();
                const headers = new HttpHeaders({
                    'x-token': localStorage.getItem('access_token')
                });
                let firmas = [];
                const aux = yield this.http.get(`${this.rutaWS}getDoc/${id_doc_generado}`, { headers }).toPromise();
                const aux1 = yield this.http.get(`${this.rutaWS}getFirmasDoc/${id_doc}`, { headers }).toPromise();
                const doc = aux.doc;
                firmas = aux1.docs;
                const url = doc.ruta_final;
                const existingPdfBytes = yield fetch(url).then(res => res.arrayBuffer());
                let pdfDoc;
                try {
                    pdfDoc = yield PDFDocument.load(existingPdfBytes);
                }
                catch (e) {
                    pdfDoc = yield PDFDocument.load(existingPdfBytes, { ignoreEncryption: true });
                }
                let pages;
                pages = pdfDoc.getPages();
                if (firmas.length > 0) {
                    for (let index = 0; index < firmas.length; index++) {
                        const img = new Image();
                        img.width = firmas[index].ancho;
                        img.height = firmas[index].alto;
                        img.src = firmas[index].data_img;
                        img.onload = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            const pngImageBytes = yield fetch(img.src).then((res) => res.arrayBuffer());
                            const pngImage = yield pdfDoc.embedPng(pngImageBytes);
                            const pngDims = pngImage.scale(0.5);
                            const page = pages[firmas[index].pagina - 1];
                            let posicionX = 0;
                            let posicionY = 0;
                            let ancho = Math.round(firmas[index].ancho);
                            let alto = Math.round(firmas[index].alto);
                            // page.setSize(iframe.find(`#page1`).width(), iframe.find(`#page1`).width())
                            const actualX = page.getWidth();
                            const actualY = page.getHeight();
                            if ((actualX == firmas[index].resolucionX) && (actualY == firmas[index].resolucionY)) {
                                posicionX = firmas[index].posicionX;
                                posicionY = firmas[index].posicionY;
                            }
                            else {
                                // Sacamos el factor de escala
                                const difX = actualX / firmas[index].resolucionX;
                                const difY = actualY / firmas[index].resolucionY;
                                posicionX = firmas[index].posicionX * difX;
                                posicionY = firmas[index].posicionY * difY;
                                ancho = ancho * difX;
                                alto = alto * difY;
                            }
                            posicionX = Math.round(posicionX);
                            posicionY = Math.round(posicionY);
                            page.drawImage(pngImage, {
                                x: posicionX,
                                y: page.getHeight() - (posicionY + (pngDims.height / 2)),
                                width: ancho,
                                height: alto,
                            });
                            if (index == firmas.length - 1) {
                                yield pdfDoc.save().then((c) => {
                                    const pdfBytes = c;
                                    const mediaType = 'application/pdf';
                                    const blob = new Blob([pdfBytes], { type: mediaType });
                                    const filename = `${doc.nombre}.pdf`;
                                    saveAs(blob, filename);
                                    const formData = new FormData();
                                    doc.descargado_receptor = 1;
                                    formData.append('usuario', '' + usuario);
                                    formData.append('rol', '' + rol);
                                    formData.append('data', JSON.stringify(doc));
                                    formData.append('pdf', blob);
                                    this.subirImagenWeb(formData).subscribe((resp) => { });
                                    resolve();
                                });
                            }
                        });
                    }
                }
                else {
                    yield pdfDoc.save().then((c) => {
                        const pdfBytes = c;
                        const mediaType = 'application/pdf';
                        const blob = new Blob([pdfBytes], { type: mediaType });
                        const filename = `${doc.nombre}.pdf`;
                        const formData = new FormData();
                        doc.descargado_receptor = 1;
                        formData.append('data', JSON.stringify(doc));
                        formData.append('pdf', blob);
                        this.subirImagenWeb(formData).subscribe((resp) => { });
                        saveAs(blob, filename);
                    });
                }
            }
            catch (e) {
                reject();
                return;
            }
        }));
    }
    descargarDocDigital(doc, original) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let url = '';
                if (original) {
                    url = doc.ruta_en_blanco;
                }
                else {
                    url = doc.ruta_final;
                }
                let disponibleDescargar;
                if (original) {
                    disponibleDescargar = true;
                }
                else {
                    disponibleDescargar = yield this.getDisponibleParaDescargar(url);
                }
                if (disponibleDescargar) {
                    this.descargarPdfFirmados(url).subscribe((data) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        this.presentToast('Documento descargado con éxito');
                        const mediaType = 'application/pdf';
                        const blob = new Blob([data], { type: mediaType });
                        const filename = `${doc.nombre}.pdf`;
                        if (!original) {
                            doc.descargado_receptor = 1;
                        }
                        /*
                        let resp:any = await this.descargado(doc.id_doc, doc.emisor, 1).toPromise();
              
                        if(resp.ok){
                          if(!original){
                            doc.descargado_receptor = 1;
                          }
                        }
                        */
                        saveAs(blob, filename);
                        resolve(true);
                    }));
                }
                else {
                    this.presentAlert('El documento ya no esta disponible para su descarga');
                    resolve(false);
                }
            }));
        });
    }
    getDatosEmisor(id) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}getDatosEmisor/${id}`, { headers });
    }
    getBioEmisor(id) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}getBioEmisor/${id}`, { headers });
    }
    getReceptoresXPadre(id) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}getReceptoresXPadre/${id}`, { headers });
    }
    setNuevoUsuario(usuario) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.post(`${this.rutaWS}setUsuarios`, usuario, { headers });
    }
    subirLogo(formData) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.post(`${this.rutaWS}uploadLogo`, formData, { headers });
    }
    subirPaginaEditada(formData) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.post(`${this.rutaWS}subirPaginaEditada`, formData, { headers });
    }
    setModificaUsuario(usuario) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.post(`${this.rutaWS}updateUsuarios`, usuario, { headers });
    }
    enviaPassword(email, rol, endpoint) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}generarPassword/${email}/${rol}/${endpoint}`, { headers });
    }
    compruebaRequisitosPassword(password) {
        // 1Mayuscula y 1 numero al menos, sin caracteres raros
        let mensaje = '';
        let mayuscula = 0;
        let numero = 0;
        let minuscula = 0;
        let caracteres_extranys = 0;
        if ((password.length >= 8) && (password.length <= 16)) {
            for (let index = 0; index < password.length; index++) {
                const cod = password.charCodeAt(index);
                if ((cod > 64) && (cod < 91)) {
                    mayuscula++;
                }
                else if ((cod > 47) && (cod < 58)) {
                    numero++;
                }
                else if ((cod > 96) && (cod < 123)) {
                    minuscula++;
                }
                else {
                    caracteres_extranys++;
                }
            }
        }
        else {
            mensaje = 'La longitud de la contraseña debe ser de un mínimo de 8 caracteres y un máximo de 16';
            return mensaje;
        }
        if (caracteres_extranys) {
            mensaje = 'Se ha encontrado caracteres extraños';
            return mensaje;
        }
        if (mayuscula == 0) {
            mensaje = 'Necesita al menos una mayúscula';
            return mensaje;
        }
        if (numero == 0) {
            mensaje = 'Se precisa al menos un número';
            return mensaje;
        }
        if (minuscula == 0) {
            mensaje = 'Se precisa al menos una minúscula';
            return mensaje;
        }
    }
    setModificaReceptor(receptor) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.post(`${this.rutaWS}updateReceptores`, receptor, { headers });
    }
    setModificaDoc(doc) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.post(`${this.rutaWS}updateDocumentos`, doc, { headers });
    }
    setEnviarEmailLink(id_doc, receptor) {
        console.log(receptor);
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        let numero = 0;
        if (receptor.numero_telefono == null || receptor.numero_telefono == '') {
            numero = 0;
        }
        else {
            numero = receptor.numero_telefono;
        }
        return this.http.get(`${this.rutaWS}setEnvioEmail/${receptor.email}/${receptor.id}/${id_doc}/${numero}/${receptor.rol}`, { headers });
    }
    setEnviarPassSms(id_doc, numero, receptor, rol) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}setEnvioSms/${id_doc}/${numero}/${receptor}/${rol}`, { headers });
    }
    comprobacionBotones(id_generado, rol, id_usuario) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}visibilidadBotones/${id_generado}/${rol}/${id_usuario}`, { headers });
    }
    getVideos() {
        return this.http.get(`${this.rutaWS}getVideos`);
    }
    pruebaDescCert(doc) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        this.http.get(`${this.rutaWS}getFirmasDoc/${doc.id_doc}`, { headers }).subscribe((resp) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const firmas = resp.docs;
            /*
            let imageContent = atob(firmas[firmas.length-1].doc_buffer);
            let view = new Uint8Array(buffer);
            for (let n = 0; n < imageContent.length; n++) {
              view[n] = imageContent.charCodeAt(n);
            }
            // create an ArrayBuffer and a view (as unsigned 8-bit)
            let buffer = new ArrayBuffer(firmas[firmas.length - 1].doc_buffer);
            // fill the view, using the decoded base64
      
            // convert ArrayBuffer to Blob
            let blob = new Blob([buffer], { type: 'pdf' });
            saveAs(blob, 'Miprieba-.pdf');
      
            */
            const dataUri = 'data:application/pdf;base64,' + firmas[firmas.length - 1].doc_buffer;
            // const existingPdfBytes = await fetch(doc.ruta_final).then(res => res.arrayBuffer())
            const pdfDoc1 = yield PDFDocument.load(dataUri);
            yield pdfDoc1.save().then((c) => {
                const pdfBytes = c;
                const mediaType = 'application/pdf';
                const blob = new Blob([pdfBytes], { type: mediaType });
                const filename = doc.id_generado;
                saveAs('data:application/pdf;base64,' + firmas[firmas.length - 1].doc_buffer, filename);
                // saveAs(blob, filename);
            });
        }));
    }
    setEnviarMensaje(data) {
        return this.http.post(`${this.rutaWS}contacto`, data);
    }
    setEnviaNewsLetter(data) {
        return this.http.post(`${this.rutaWS}altaNewsletter`, data);
    }
    verificarCSV(formdata) {
        return this.http.post(`${this.rutaWS}validarcsv`, formdata);
    }
    getPlanes() {
        return this.http.get(`${this.rutaWS}getPlanes`);
    }
    getPaises() {
        return this.http.get(`${this.rutaWS}getPaises`);
    }
    setAltaUsuario(data) {
        return this.http.post(`${this.rutaWS}altaUsuario`, data);
    }
    getLogoReceptor(empresa) {
        return this.http.get(`${this.rutaWS}getLogoReceptor/${empresa}`);
    }
    getReceptorDocumentos(id) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}getDocumentosReceptor/${id}`, { headers });
    }
    getAviso(id) {
        return this.http.get(`${this.rutaWS}avisos/${id}`);
    }
    setRecuperaPass(email, rol, empresa) {
        return this.http.get(`${this.rutaWS}generarPassword/${email}/${rol}/${empresa}`);
    }
    presentToast(message) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toastController.create({
                message,
                duration: 2000
            });
            toast.present();
        });
    }
    descargaPdfMovil(ruta, doc) {
        return new Promise((resolve, reject) => {
            const fileTransfer = this.fileTransfer.create();
            let storageDirectory = '';
            // Select Storage Location
            if (sessionStorage.getItem('plataforma') == 'IOS') {
                storageDirectory = this.file.documentsDirectory + 'FirmaContratos/';
            }
            else if (sessionStorage.getItem('plataforma') == 'ANDROID') {
                // storageDirectory = this.file.externalDataDirectory + '<your app name>/';
                // storageDirectory = this.file.externalDataDirectory + 'firmaContratos/'
                storageDirectory = this.file.externalRootDirectory + '/Download/';
                // storageDirectory = this.file.externalRootDirectory + '/Download/';
                // this.presentToast('Soy Android ' + storageDirectory);
            }
            else {
                return false;
            }
            // Request Access
            if (sessionStorage.getItem('plataforma') == 'ANDROID') {
                this.diagnostic.requestRuntimePermission('WRITE_EXTERNAL_STORAGE').then(() => {
                });
                this.diagnostic.requestRuntimePermission('READ_EXTERNAL_STORAGE').then(() => {
                });
            }
            // Download Image
            const uri = encodeURI(ruta);
            const fileURL = storageDirectory + doc.nombre.replace(/ /g, '%20');
            fileTransfer.download(uri, fileURL).then((e) => {
                resolve(e);
            }, (error) => {
                reject(error);
                // handle error
            });
        });
    }
    getPaginasFirmadas(id_doc) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}getPaginasFirmadas/${id_doc}`, { headers });
    }
    getPruebaFirma(firma, doc, pass) {
        const form = new FormData();
        const data = {
            certificado: firma,
            pdf: doc,
            pass
        };
        form.append('data', JSON.stringify(data));
        return this.http.post(`${this.rutaWS}pruebaFirmaCert`, form);
    }
    getDniPasaporteValido(dni) {
        let numero, letra;
        /* cadena se corresponde con la referencia que indica la letra asociada al DNI*/
        let cadena = 'TRWAGMYFPDXBNJZSQVHLCKET';
        /* expresion que indica: */
        /* al principio puede ser X, Y o Z*/
        /* después entre 5 y 8 dígitos */
        /* al final letra entre la A y la Z*/
        const expr = /^[XYZ]?\d{5,8}[A-Z]$/;
        dni = dni.toUpperCase();
        /* test() permite encontrar el patrón en la variable */
        if (!expr.test(dni)) {
            return false;
        }
        /* quitamos la letra del final*/
        numero = dni.substr(0, dni.length - 1);
        /* tratamos los casos de los nie's */
        numero = numero.replace('X', 0);
        numero = numero.replace('Y', 1);
        numero = numero.replace('Z', 2);
        /* letra última del dni*/
        letra = dni.substr(dni.length - 1, 1);
        numero = numero % 23;
        /* averiguo la letra en función de la operación anterior*/
        cadena = cadena.substring(numero, numero + 1);
        if (letra !== cadena) {
            return false;
        }
        return true;
    }
    getTiene_letras(dni) {
        const letras = 'abcdefghyjklmnñopqrstuvwxyz';
        dni = dni.toLowerCase();
        for (let i = 0; i < dni.length; i++) {
            if (letras.indexOf(dni.charAt(i), 0) !== -1) {
                return true;
            }
        }
        return false;
    }
    getCertificados() {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        const rutaCert = `${environment.rutaWS}${environment.puerto}/`;
        return this.http.get(`${rutaCert}pruebaFirma/cb64hjb5wkanu4nqk`, { headers });
    }
    setGrabaRubrica(formdata) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.post(`${this.rutaWS}setGrabaRubrica`, formdata, { headers });
    }
    setBorraRubrica(id) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}borraRubrica/${id}`, { headers });
    }
    getParametrosPago(formdata) {
        return this.http.post(`https://firmacontratos.es/PagoFirmaContratos/api.php`, formdata);
    }
    setEnviaPago(pago) {
        window.location.href = `https://firmacontratos.es/PagoFirmaContratos/api.php?price=${pago.precio}&recibo=${pago.recibo}`;
    }
    setRealizaPago(formdata) {
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.post(`https://firmacontratos.es/PagoFirmaContratos/api.php`, formdata);
    }
    setEditaPdfServer(formdata) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.post(`${this.rutaWS}setEditaPdf`, formdata, { headers });
    }
    setRegistraPago(formdata) {
        return this.http.post(`${this.rutaWS}setRegistraPago`, formdata);
    }
    setHazPago(datos, desarrollo) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        let ruta = '';
        // let ruta = `https://sis.redsys.es/sis/rest/trataPeticionREST`;
        if (desarrollo) {
            ruta = `https://sis-t.redsys.es:25443/sis/realizarPago`;
        }
        else {
            ruta = `https://sis.redsys.es/sis/realizarPago`;
        }
        return this.http.post(`${ruta}`, datos, { headers });
    }
    setactualizaPago(datos) {
        return this.http.post(`${this.rutaWS}setActualizaPago`, datos);
    }
    setAsignaReceptorAgente(agenteId, receptorId, checked) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}setAsignaReceptorAgente/${agenteId}/${receptorId}/${checked}`, { headers });
    }
    getReceptorDoc(id_generado) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}getReceptorXDoc/${id_generado}`, { headers });
    }
    getFirmantesDoc(id_generado) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}getFirmantesXDoc/${id_generado}`, { headers });
    }
    getReceptor(id) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}getReceptor/${id}`, { headers });
    }
    setActualizaFirma(formData) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.post(`${this.rutaWS}setActualizaFirma`, formData, { headers });
    }
    setEnviaEnlaceDescarga(rol, id_usuario, id_generado, email) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}setEnviaEnlaceDescarga/${rol}/${id_usuario}/${id_generado}/${email}`, { headers });
    }
    getAgentesUsuario(id) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}getAgentesUsuario/${id}`, { headers });
    }
    getAyuda() {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}getAyuda`, { headers });
    }
    setActualizaFirmante(firmante) {
        const formData = new FormData();
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        formData.append('firmante', JSON.stringify(firmante));
        return this.http.post(`${this.rutaWS}setEditaFirmante`, formData, { headers });
    }
    setInsertaFirmante(firmante) {
        const formData = new FormData();
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        formData.append('firmante', JSON.stringify(firmante));
        return this.http.post(`${this.rutaWS}setInsertaFirmante`, formData, { headers });
    }
    setEliminaFirmante(id, id_doc) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}setEliminaFirmante/${id}/${id_doc}`, { headers });
    }
    setEditaDoc(documento) {
        const formData = new FormData();
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        formData.append('doc', JSON.stringify(documento));
        return this.http.post(`${this.rutaWS}setEditaDoc`, formData, { headers });
    }
    setFirmasFirmante(id) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}setFirmasFirmante/${id}`, { headers });
    }
    getFirmadoPorTodos(id_doc, envioMails) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}firmadoPorTodos/${id_doc}/${envioMails}`, { headers });
    }
    getReenvioPassword(form) {
        return this.http.post(`${this.rutaWS}solicitarReenvio`, form);
    }
    getReenvioPass(identificador) {
        return this.http.get(`${this.rutaWS}getReenvioPass/${identificador}`);
    }
    setCopiarDocumentos(datos) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token'),
            'Content-Type': 'application/json'
        });
        return this.http.post(`${this.rutaWS}setCopiaDocs`, datos, { headers });
    }
    getCompruebaEnlace(rol, id_firmante, doc) {
        const id_generado = doc.id_generado.split('.pdf');
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}getCompruebaEnlace/${rol}/${id_firmante}/${id_generado[0]}`, { headers });
    }
    getContratoEmisor(emisor) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}getContratoEmisor/${emisor}`, { headers });
    }
    getAccesoBiometrico(receptor_firmante, rol, id_generado) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}getAccesoBiometrico/${rol}/${receptor_firmante}/${id_generado}`, { headers });
    }
    setAccesoBiometrico(formdata) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.post(`${this.rutaWS}setInsertaAccesoBiometrico`, formdata, { headers });
    }
    setBioVerificado(id) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}setBioVerificado/${id}`, { headers });
    }
    setBioRechazado(id) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}setBioRechazado/${id}`, { headers });
    }
    getUsuario(id) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}getUsuario/${id}`, { headers });
    }
    setGrabaAccion(rol, usuario_firmante, id_generado, accion) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}setGrabaAccion/${rol}/${usuario_firmante}/${id_generado}/${accion}`, { headers });
    }
    getAvisosPagina(pagina) {
        return this.http.get(`${this.rutaWS}getAvisosPagina/${pagina}`);
    }
    setAceptaCondicionesDoc(id_generado) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}setAceptaCondiciones/${id_generado}`, { headers });
    }
    getCodificaDatosPago(formdata) {
        return this.http.post(`https://firmacontratos.es/PagoFirmaContratos/api_redireccion.php`, formdata);
    }
    setNuevoContrato(formdata) {
        return this.http.post(`${this.rutaWS}setRenovacionContratoPagoPersonalizado`, formdata);
    }
    getUsuarioSinToken(id) {
        return this.http.get(`${this.rutaWS}getPadreSinToken/${id}`);
    }
    getTodosPosicionados(id_doc) {
        return new Promise((resolve) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.auth.sigueEnSesion();
            const headers = new HttpHeaders({
                'x-token': localStorage.getItem('access_token')
            });
            yield this.http.get(`${this.rutaWS}getTodosPosicionados/${id_doc}`, { headers }).toPromise().then((resp) => {
                if (resp.ok) {
                    if (resp.faltan != null && resp.faltan !== undefined) {
                        if (resp.faltan == '') {
                            resolve(true);
                        }
                        else {
                            const mensaje = `Falta posicionar las firmas para los siguientes firmantes: ${resp.faltan}`;
                            this.presentAlertConfirm(mensaje);
                            resolve(false);
                        }
                    }
                }
                resolve(false);
            });
        }));
    }
    envioCredencialesApi(email) {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}envioCredencialesApi/${email}`, { headers });
    }
    presentAlertConfirm(message) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alert.create({
                header: '¡Atención!',
                message,
                buttons: [
                    {
                        text: 'Aceptar'
                    }
                ]
            });
            yield alert.present();
        });
    }
    getDB() {
        this.auth.sigueEnSesion();
        const headers = new HttpHeaders({
            'x-token': localStorage.getItem('access_token')
        });
        return this.http.get(`${this.rutaWS}getDB`, { headers });
    }
}
PrincipalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PrincipalService_Factory() { return new PrincipalService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.AuthService), i0.ɵɵinject(i4.Device), i0.ɵɵinject(i5.Platform), i0.ɵɵinject(i5.AlertController), i0.ɵɵinject(i6.File), i0.ɵɵinject(i7.FileTransfer), i0.ɵɵinject(i8.Diagnostic), i0.ɵɵinject(i5.ToastController)); }, token: PrincipalService, providedIn: "root" });
