import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EncabezadoComponent } from './encabezado/encabezado.component';
import { PieComponent } from './pie/pie.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { NuevoUserComponent } from './nuevo-user/nuevo-user.component';
import { PopoverComponent } from './popover/popover.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalFirmantesComponent } from './modal-firmantes/modal-firmantes.component';
import { IntlInputPhoneModule } from 'intl-input-phone';
import { EncabezadoHomeComponent } from './encabezado-home/encabezado-home.component';
import { ModalRubricasComponent } from './modal-rubricas/modal-rubricas.component';
import { FirmaPadComponent } from './firma-pad/firma-pad.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { PasarelaPagoComponent } from './pasarela-pago/pasarela-pago.component';
import { AyudaComponent } from './ayuda/ayuda.component';
import { PipesModule } from '../pipes/pipes/pipes.module';
import { EditarDocComponent } from './editar-doc/editar-doc.component';
import { PopReceptoresComponent } from './pop-receptores/pop-receptores.component';
import { CompruebaPassObiometricoComponent } from './comprueba-pass-obiometrico/comprueba-pass-obiometrico.component';
import { BiometricoComponent } from './biometrico/biometrico.component';
import { ValidacionBiometricoComponent } from './validacion-biometrico/validacion-biometrico.component';
import { CondicionesReceptorComponent } from './condiciones-receptor/condiciones-receptor.component';
import { ModalPlantillasComponent } from './modal-plantillas/modal-plantillas.component';
import { ModalApiComponent } from './modal-api/modal-api.component';
import { ModalGuardarPlantillaComponent } from './modal-guardar-plantilla/modal-guardar-plantilla.component';


@NgModule({
  entryComponents: [
    PopoverComponent
  ],
  declarations: [
    EncabezadoComponent,
    PieComponent,
    UploadFileComponent,
    NuevoUserComponent,
    PopoverComponent,
    ModalFirmantesComponent,
    EncabezadoHomeComponent,
    ModalRubricasComponent,
    FirmaPadComponent,
    PasarelaPagoComponent,
    AyudaComponent,
    EditarDocComponent,
    PopReceptoresComponent,
    CompruebaPassObiometricoComponent,
    BiometricoComponent,
    ValidacionBiometricoComponent,
    CondicionesReceptorComponent,
    ModalPlantillasComponent,
    ModalApiComponent,
    ModalGuardarPlantillaComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    IntlInputPhoneModule,
    SignaturePadModule,
    PipesModule
  ],
  exports: [
    EncabezadoComponent,
    PieComponent,
    UploadFileComponent,
    NuevoUserComponent,
    PopoverComponent,
    ModalFirmantesComponent,
    EncabezadoHomeComponent,
    ModalRubricasComponent,
    FirmaPadComponent,
    PasarelaPagoComponent,
    AyudaComponent,
    EditarDocComponent,
    PopReceptoresComponent,
    CompruebaPassObiometricoComponent,
    BiometricoComponent,
    ValidacionBiometricoComponent,
    CondicionesReceptorComponent,
    ModalPlantillasComponent,
    ModalApiComponent,
    ModalGuardarPlantillaComponent
  ]
})
export class ComponentesModule { }
