import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
@Pipe({
  name: 'filtrador'
})
export class FiltradorPipe implements PipeTransform {
  transform(array, args) {        
    let arraux = [];
        
    switch (args) {
      case 'fecha_creacion(ASC)':
        array = _.sortBy(array, 'fecha_creacion');
        arraux = array.filter(e => e.fijar == 1);
        array = array.filter(e => e.fijar == 0);
        arraux = arraux.reverse();
        arraux.forEach(a => {
          array.unshift(a);
        })
        return array;
        break;
      case 'nombre(ASC)':
        array = _.sortBy(array, 'nombre');
        arraux = array.filter(e => e.fijar == 1);
        array = array.filter(e => e.fijar == 0);
        arraux = arraux.reverse();
        arraux.forEach(a => {
          array.unshift(a);
        })
        return array;
        break;
      case 'alias(ASC)':
        array = _.sortBy(array, 'alias');
        arraux = array.filter(e => e.fijar == 1);
        array = array.filter(e => e.fijar == 0);
        arraux = arraux.reverse();
        arraux.forEach(a => {
          array.unshift(a);
        })
        return array;
        break;        
      case 'totalAfirmar(ASC)':
        array = _.sortBy(array, 'totalAfirmar'); 
        arraux = array.filter(e => e.fijar == 1);
        array = array.filter(e => e.fijar == 0);
        arraux = arraux.reverse();
        arraux.forEach(a => {
          array.unshift(a);
        })
        return array;
        break;        
      case 'fecha_creacion(DESC)':
        array = _.sortBy(array, 'fecha_creacion').reverse(); 
        arraux = array.filter(e => e.fijar == 1);
        array = array.filter(e => e.fijar == 0);
        arraux = arraux.reverse();
        arraux.forEach(a => {
          array.unshift(a);
        })
        return array;
        break;        
      case 'nombre(DESC)':
        array = _.sortBy(array, 'nombre').reverse(); 
        arraux = array.filter(e => e.fijar == 1);
        array = array.filter(e => e.fijar == 0);
        arraux = arraux.reverse();
        arraux.forEach(a => {
          array.unshift(a);
        })
        return array;
        break;        
      case 'alias(DESC)':
        array = _.sortBy(array, 'alias').reverse(); 
        arraux = array.filter(e => e.fijar == 1);
        array = array.filter(e => e.fijar == 0);
        arraux = arraux.reverse();
        arraux.forEach(a => {
          array.unshift(a);
        })
        return array;
        break;        
      case 'totalAfirmar(DESC)':
        array = _.sortBy(array, 'totalAfirmar').reverse(); 
        arraux = array.filter(e => e.fijar == 1);
        array = array.filter(e => e.fijar == 0);
        arraux = arraux.reverse();
        arraux.forEach(a => {
          array.unshift(a);
        })
        return array;
        break;  
      default:
        arraux = array.filter(e=> e.fijar == 1);
        array = array.filter(e => e.fijar == 0);
        arraux = arraux.reverse();
        arraux.forEach(a => {
          array.unshift(a);
        })
        return array;
    }
  }

}
