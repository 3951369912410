/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./encabezado.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
import * as i6 from "./encabezado.component";
import * as i7 from "../../servicios/auth.service";
var styles_EncabezadoComponent = [i0.styles];
var RenderType_EncabezadoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EncabezadoComponent, data: {} });
export { RenderType_EncabezadoComponent as RenderType_EncabezadoComponent };
export function View_EncabezadoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "ion-toolbar", [["color", "granate"]], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(1, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "a", [["class", "cursor"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 737280, null, 0, i3.RouterLinkDelegate, [i4.LocationStrategy, i3.NavController, i1.ElementRef, i5.Router, [2, i5.RouterLink]], null, null), i1.ɵdid(4, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", ""], ["id", "logo"], ["src", "../../../assets/imgs/logolinea.png"], ["srcset", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, 0, 5, "ion-buttons", [["slot", "end"]], null, null, null, i2.View_IonButtons_0, i2.RenderType_IonButtons)), i1.ɵdid(7, 49152, null, 0, i3.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(8, 0, null, 0, 3, "ion-button", [["title", "Desconectar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(9, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(10, 0, null, 0, 1, "ion-icon", [["class", "cursor ion-margin-end"], ["color", "light"], ["name", "log-out-outline"], ["size", "large"], ["slot", "icon-only"], ["title", "Desconectar"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(11, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], name: [1, "name"], size: [2, "size"] }, null)], function (_ck, _v) { var currVal_0 = "granate"; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); var currVal_3 = "/home"; _ck(_v, 4, 0, currVal_3); var currVal_4 = "light"; var currVal_5 = "log-out-outline"; var currVal_6 = "large"; _ck(_v, 11, 0, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 4).target; var currVal_2 = i1.ɵnov(_v, 4).href; _ck(_v, 2, 0, currVal_1, currVal_2); }); }
export function View_EncabezadoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-encabezado", [], null, null, null, View_EncabezadoComponent_0, RenderType_EncabezadoComponent)), i1.ɵdid(1, 114688, null, 0, i6.EncabezadoComponent, [i7.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EncabezadoComponentNgFactory = i1.ɵccf("app-encabezado", i6.EncabezadoComponent, View_EncabezadoComponent_Host_0, {}, {}, []);
export { EncabezadoComponentNgFactory as EncabezadoComponentNgFactory };
