import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ionic/storage";
import * as i3 from "@angular/common/http";
export class AuthService {
    constructor(router, storage, http) {
        this.router = router;
        this.storage = storage;
        this.http = http;
        this.rutaWS = `${environment.rutaWS}${environment.puerto}/`;
    }
    // auth0 = new auth0.WebAuth({
    //   clientID: '4H5gt8Zn6p1PLwxMcIaRa9YayKQzWVFk',
    //  /* domain: 'strider.auth0.com',*/
    //   responseType: 'token id_token',
    //  /* audience: 'https://strider.auth0.com/userinfo',*/
    //   redirectUri: 'http://localhost:8100/callback',
    //   scope: 'openid profile'
    // });
    grabaSession(token) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.setSession(token.token);
            yield this.storage.remove('usuario');
            yield this.storage.set('usuario', token.usuario);
            if (token.usuario.cambiaRoles == 1) {
                yield this.storage.remove('superUser');
                yield this.storage.set('superUser', {
                    super: true,
                    id: token.usuario.id
                });
            }
            else {
                yield this.storage.remove('superUser');
                yield this.storage.set('superUser', {
                    super: false
                });
            }
            if (token.usuario.rol == 4) {
                this.router.navigate(['/directorio-agentes']);
            }
            else {
                this.router.navigate(['/directorio']);
            }
        });
    }
    grabaSessionDescarga(token) {
        this.setSession(token);
    }
    grabaSessionInvitado(resp) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.setSession(resp.resultado.token);
            yield this.storage.remove('receptor');
            return yield this.storage.set('receptor', resp.receptor);
        });
    }
    grabaSessionReceptor(resp) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.setSession(resp.respuesta);
            yield this.storage.remove('receptor');
            return yield this.storage.set('receptor', resp.receptor);
        });
    }
    setSession(token) {
        // Set the time that the access token will expire at
        const expiresAt = JSON.stringify((1000 * 60 * 60) + new Date().getTime());
        localStorage.setItem('access_token', token);
        localStorage.setItem('expires_at', expiresAt);
    }
    removeSession() {
        this.storage.remove('usuario');
        this.storage.remove('receptor');
        // Remove tokens and expiry time from localStorage
        localStorage.removeItem('access_token');
        localStorage.removeItem('expires_at');
    }
    logout() {
        this.storage.remove('usuario');
        // Remove tokens and expiry time from localStorage
        localStorage.removeItem('access_token');
        localStorage.removeItem('expires_at');
        // Go back to the home route
        this.router.navigate(['/login']);
    }
    isAuthenticated() {
        // Check whether the current time is past the
        // access token's expiry time
        const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
        return new Date().getTime() < expiresAt;
    }
    getProfile(cb) {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
            throw new Error('Access token must exist to fetch profile');
        }
    }
    sigueEnSesion() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            /* Vamos a ver si seguimos en session tanto abajo como arriba */
            if (this.isAuthenticated()) {
                const headers = new HttpHeaders({
                    'x-token': localStorage.getItem('access_token')
                });
                return this.http.get(`${this.rutaWS}validado`, { headers }).subscribe((resp) => {
                    if (resp.respuesta) {
                        localStorage.removeItem('expires_at');
                        const expiresAt = JSON.stringify((1000 * 60 * 60) + new Date().getTime());
                        localStorage.setItem('expires_at', expiresAt);
                        return true;
                    }
                    else {
                        this.router.navigate(['/login']);
                    }
                });
            }
            else {
                this.router.navigate(['/login']);
                return false;
            }
        });
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Storage), i0.ɵɵinject(i3.HttpClient)); }, token: AuthService, providedIn: "root" });
